<script setup lang="ts">
import {
  EventFieldsFragment,
  LandingPageFieldsFragment,
  NodeCbzLandingPage,
  NewsFieldsFragment,
  NodeProtectedPage,
  OpenJobFieldsFragment,
  PageFieldsFragment,
  ToccoSeminarFieldsFragment,
  ContainerWidgetMain,
} from '~/api/graphql/generated'

const props = defineProps<{
  entity:
    | PageFieldsFragment
    | EventFieldsFragment
    | OpenJobFieldsFragment
    | NodeCbzLandingPage
    | NodeProtectedPage
    | LandingPageFieldsFragment
    | NewsFieldsFragment
    | ToccoSeminarFieldsFragment
    | ContainerWidgetMain
}>()

const sections = useSections(props.entity.fieldWidgets)
</script>

<template>
  <component
    :is="section?.name"
    v-for="(section, index) in sections"
    :key="`${section?.name}-${index}`"
    :entity="section.entity"
    v-bind="{
      ...(section.entity.fieldId && { 'data-id': section.entity.fieldId }),
    }"
  />
</template>
